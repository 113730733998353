<template>
  <div>
    <div class="w-1200">
      <h3>
        各层级晋升渠道
      </h3>
      <p>管理与专业序列既是平衡对应关系，又可交叉晋升发展。</p>
      <img class="ispc" src="../../assets/img/2021/recurit_train.png" alt="">
      <!-- <img class="ismoblie" src="../../assets/img/2021/recurit_train_2.png" alt="">
      <img class="ismoblie" style="margin-top: 25px;" src="../../assets/img/2021/recurit_train_3.png" alt=""> -->
    </div>
    <div class="grey">
      <div class="w-1200">
        <h3>
          福利待遇
        </h3>
        <p>弘金地致力于为员工提供业界具有竞争力的薪酬，并提供 一系列的福利，实现员工工作与生活的平衡。</p>
        <ul class="list">
          <li>
            <span>基本工资</span>
            <p>按照岗位、能力、绩效的薪酬理念付薪，适时进行多维度的专项调薪。</p>
          </li>
          <li>
            <span>绩效考核</span>
            <p>根据员工绩效表现 、年度根据公司绩效、组织绩效与个人绩效情况发放年终奖 。</p>
          </li>
          <li>
            <span>保险保障</span>
            <p>为员工缴纳五险（养老、医疗、工伤、失业、生育）一金（住房公积金）。</p>
          </li>
          <li>
            <span>其它福利</span>
            <p>工作日午餐、交通补贴、工服补贴、过节费、高温补贴、生日礼物、年度旅游基金等。</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="w-1200">
      <h3>
        员工风采
      </h3>
      <p>公司每年在计划内根据实际情况，组织进行不定形式的各类活动。</p>
      <div class="banner-warp" v-if="isPc">
        <button class="arrow arrow-left" @click="changeBanner(true)">
          <i class="el-icon el-icon-arrow-left"></i>
        </button>
        <button class="arrow arrow-right" @click="changeBanner(false)">
          <i class="el-icon el-icon-arrow-right"></i>
        </button>
        <el-carousel height="244px" ref="carousel"
                     @change="autoChange"
                     :autoplay="false" :loop="false"
                     indicator-position="none" arrow="never">
          <el-carousel-item v-for="(item,idx) in listLen" :key="idx">
            <div class="cc-item" v-for="num in 3"
                 :class="{mar:num===2}"
                 v-if="idx*3+num-1<list.length" :key="num*10">
              <img :src="list[idx*3+num-1].src" alt="">
              <span>{{ list[idx * 3 + num - 1].srcDesc }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="m-banner-warp" v-else>
        <el-carousel class="swiper-mobile-2" height="244px" ref="slideCarousel"
                     @change="autoChange"
                     :autoplay="true" :loop="true"
                     indicator-position="none" arrow="never">
          <el-carousel-item v-for="(item,idx) in list" :key="idx">
            <div class="cc-item">
              <img :src="item.src" alt="">
              <span>{{ item.srcDesc }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

    </div>

  </div>
</template>

<script>
import cluImage1 from '../../assets/img/2021/train/cul_1.png'
import cluImage2 from '../../assets/img/2021/train/cul_2.png'
import cluImage3 from '../../assets/img/2021/train/cul_3.png'
import cluImage4 from '../../assets/img/2021/train/cul_4.png'
import cluImage5 from '../../assets/img/2021/train/cul_5.png'
import cluImage6 from '../../assets/img/2021/train/cul_6.png'
export default {
  data(){
    return{
      isPc:true,
      list:[{src:cluImage1,srcDesc:"端午节活动"},
        {src:cluImage2,srcDesc:"弘金地10周年活动"},
        {src:cluImage3,srcDesc:"金地集团司庆日"},
        {src:cluImage4,srcDesc:"圣诞节、平安夜活动"},
        {src:cluImage5,srcDesc:"下午茶"},
        {src:cluImage6,srcDesc:"运动嘉年华"}],
      curIdx:0
    }
  },
  computed:{
    listLen(){
      return Math.ceil(this.list.length/3)
    }
  },
  mounted() {
    this.isPc=this.IsPC()
    this.$nextTick(()=>{
      if(!this.isPc) this.slideBanner()
    })
  },
  methods:{
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.swiper-mobile-2');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        // console.log("1：" + startPoint);
        // console.log("2：" + stopPoint);
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },
    IsPC() {
      let userAgentInfo = navigator.userAgent;
      let Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"
      ];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    autoChange(idx){
      this.curIdx=idx
    },
    changeBanner(prev=false){

      if(prev){
        if(this.curIdx===0) {
          // this.curIdx = this.listLen - 1
          return false
        }else{
          this.curIdx -=1
        }
      }else{
        if(this.curIdx===this.listLen - 1) {
          // this.curIdx = 0
          return false
        }else{
          this.curIdx +=1
        }
      }
      this.$refs.carousel.setActiveItem(this.curIdx)
    }
  }
}
</script>

<style lang="less" scoped>
h3{
  font-size: 18px;
  line-height: 33px;
  color: #ea5431;
  padding-top: 50px;
  padding-bottom: 14px;
}
p{
  font-size: 16px;
  color: #333333;
  padding-bottom: 50px;
}
.grey{
  background-color: #f5f5f5;
  margin-top: 50px;
  padding-bottom: 30px;
}
.list{
  li{
    display: block;
    font-size: 16px;
    span{
      color: #ea5431;
      padding-right: 20px;
    }
    p{
      display: inline-block;
      padding-bottom: 20px;
    }
  }
}
.ismoblie{
  display: none;
}
@media screen and (max-width: 1024px) {
  .ismoblie{
    display: block;
  }
  .ispc{
    display: none;
  }
  .w-1200{
    padding: 0 20px;
  }
  h3{
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 10px;
  }
  p{
    font-size: 16px;
    padding-bottom: 20px;
  }
  .grey{
    margin-top: 25px;
  }
}
.banner-warp{
  padding: 0 63px;
  margin-bottom: 80px;
  position: relative;
  .arrow{
    width: 44px;
    height: 44px;
    background-color: #dcdcdc;
    color: #fff;
    font-size: 34px;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    line-height: 44px;
    position: absolute;
    top: 50%;
    margin-top: -22px;
    transition: all .3s;
    &.arrow-left{
      left: 0;
    }
    &.arrow-right{
      right: 0;
    }
    &:hover{
      background-color: #ea5431;
    }
  }
  .cc-item{
    width: 33%;
    float: left;
    position: relative;
    img{
      width: 100%;
      height: 244px;
    }
    &.mar{
      margin: 0 .5%;
    }
    span{
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #ea5431;
      color: #fff;
      font-size: 13px;
      line-height: 1.5;
      padding: 0 8px;
    }
  }
}

.m-banner-warp{
  margin-bottom: 40px;
  .cc-item,img{
    width: 100%;
    height: 224px;
  }
  .cc-item{
    position: relative;
    span{
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #ea5431;
      color: #fff;
      font-size: 13px;
      line-height: 1.5;
      padding: 0 8px;
    }
  }
}
</style>
